import { Component, Vue } from "vue-property-decorator";

import { STHeader,STFooter } from "@/layout";
import { APLightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { HomeworkModel } from "@/models";
import { StCommon } from "../Common";

export class QuizForm {
    shid = '';
    name = '';
    student = '';
    city = '';
    school = '';
    class = '';
    grade = '';
    unit = '';
    level = '';
    question = [];
    currentQuestion = 0;
    total = 0;
    date = '';
    active = 0;
    score = 0;
    completeDate = '';
    token = window.localStorage.getItem('studentToken') as string;
}

type response = { [key: string]: unknown };

@Component<ApStudentHomeworkTopicController>({
    components: {
        STHeader, APLightbox, STFooter
    }
})
export default class ApStudentHomeworkTopicController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private quizForm = new QuizForm();
    private stid = window.localStorage.getItem('student_stid') as string;
    private name = window.localStorage.getItem('student_name') as string;
    private token = window.localStorage.getItem('studentToken') as string;
    private hid = this.$route.query.hid as string;

    private lightbox: { showLB: boolean, errorMsg: string, confirmLink: string, confirmWord: string } = {
        showLB: false, 
        errorMsg:  '',
        confirmLink: '',
        confirmWord: '',
    };

    private errorMsgMap: { [key: string]: string } = {
        studentAnswer: '',
    };

    public async created() {
        // 檢查該組卷該學生是否已填寫過，並撈出題目
        const item: { [key: string]: string } = {
            stid: this.stid,
            hid: this.hid,
            token: this.token,
        }
        const data = await HomeworkModel.checkWrite(item) as response;
        this.assignDataToForm(data);
        
        if (this.quizForm.currentQuestion > this.quizForm.total) {
            this.quizForm.currentQuestion = 1;
        }
        if (!this.quizForm.shid) { //沒寫過
            this.getPaperQuestionList();
        }
    }

    private assignDataToForm(data: response) {
        StCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as {
            shid: string;
            name: string;
            student: string;
            city: string;
            school: string;
            class: string;
            unit: string;
            level: string;
            grade: string;
            question: [];
            total: number;
            date: string;
            currentQuestion: number;
            completeDate: string;
            active: number;
            score: number;
        };
        this.quizForm.shid = item.shid;
        this.quizForm.name = item.name;
        this.quizForm.student = item.student;
        this.quizForm.city = item.city;
        this.quizForm.school = item.school;
        this.quizForm.class = item.class;
        this.quizForm.unit = item.unit;
        this.quizForm.level = item.level;
        this.quizForm.grade = item.grade;
        this.quizForm.question = item.question;
        this.quizForm.total = item.total;
        this.quizForm.date = item.date;
        this.quizForm.currentQuestion = item.currentQuestion;
        this.quizForm.active = item.active;
        this.quizForm.score = item.score as number;
        this.quizForm.completeDate = item.completeDate;
    }

    //紀錄該卷資料
    public async getPaperQuestionList() {
        const item: { [key: string]: string } = {
            stid: this.stid,
            hid: this.hid,
            token: this.token,
        }
        const data = await HomeworkModel.stGetPaperQuestion(item) as response;
        this.assignDataToForm(data);
    }

    //上一題
    public async prevQuestion() {
        this.errorMsgMap['studentAnswer'] = '';

        if (!this.doubleClick) {
            this.doubleClick = true;

            this.quizForm.currentQuestion = this.quizForm.currentQuestion - 1;
            this.doubleClick = false;
        }
    }

    //下一題，存答案
    public async nextQuestion() {
        this.quizForm.active = 0;
        await this.editAnswer();
        if (this.doubleClick) {
            this.doubleClick = false;
            this.quizForm.currentQuestion = this.quizForm.currentQuestion + 1;
        }
    }

    //最後一題，存答案，不提交
    public async storeQuiz() {
        this.quizForm.active = 3;
        await this.editAnswer();
        if (this.doubleClick) {
            this.doubleClick = false;
            this.$router.push("/student/homework");
        }
    }

    //最後一題，存答案，並提交老師審核
    public async completeQuiz() {
        this.quizForm.active = 1;
        await this.editAnswer();
        if (this.doubleClick) {
            this.doubleClick = false;
            this.lightbox.errorMsg = '您已完成作業，並已提交給老師';
            this.lightbox.showLB = true;
            this.lightbox.confirmLink = '/student/homework';
            this.lightbox.confirmWord = '返回作業列表';
        }
    }

    public async editAnswer() {
        this.errorMsgMap['studentAnswer'] = '';

        if (this.quizForm.question[Number(this.quizForm.currentQuestion) - 1]['studentAnswer'] == '') {
            this.errorMsgMap['studentAnswer'] = '必填'; //放入error message
            return; 
        }

        if (!this.doubleClick) {
            this.doubleClick = true;

            const item: { token: string, shid: string, active: number, hqid: string, studentAnswer: string } = {
                token: this.token,
                shid: this.quizForm.shid,
                active: this.quizForm.active,
                hqid: '',
                studentAnswer: '',
            }

            for (const [key, option] of Object.entries(this.quizForm.question)) {
                if(Number(key) + 1 === this.quizForm.currentQuestion) {
                    item.hqid = option['hqid'];
                    item.studentAnswer = option['studentAnswer'];
                    break;
                }
            }

            const errNo = await HomeworkModel.stEditAnswer(item);
            switch (errNo) {
                case ErrorCode.InvalidToken:
                    StCommon.logout();
                    this.doubleClick = false;
                    break;
                case ErrorCode.Success:
                    break;
                default:
                    this.lightbox.errorMsg = ErrorMessage[errNo];
                    this.lightbox.showLB = true;
                    this.doubleClick = false;
                    break;
            }
        }
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}